.button-container {
  display: block;
  width: 184px;
  text-align: center;
  height: 2.5rem;
}

.button-container button {
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 0.75px;
  border: none;
  height: 2.5rem;
  width: 100%;
  border-radius: 1.5rem;
  color: var(--main-button-color);
}

.button-container.fullWidth {
  width: 100%;
}

.button-container button.outlined {
  box-shadow: 0 0 0 1.5px;
}

.button-container button.white {
  background: white;
}

.button-container button.white.active {
  background: var(--main-button-color);
  color: white !important;
}

.button-container button.primary.active {
  background: #00AF9B;
  opacity: 1;
}

.button-container button.primary {
  background: #00AF9B;
  opacity: 0.6;
}

.button-container button.secondary {
  background: #A0A3BD;
  opacity: 0.5;
}

.button-container button.secondary.active {
  opacity: 1;
}

.button-container button.transparent {
  background: inherit;
}

.button-container button.transparent.active {
  box-shadow: 0 0 0 1px;
}

.button-container button.black {
  background: #000;
  opacity: 0.5;
}

.button-container button.black.active {
  opacity: 1;
}

.button-container button:disabled {
  opacity: .5;
  background-color: #4E4B66;
}
