.side-panel {
  position: sticky;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 35%;
  background-color: #F8F9FF66;
  height: 100%;
}

.side-panel .logo-container {
  margin-top: 81px;
  margin-left: 64px;
  margin-bottom: 36px;
}

.side-panel .logo {
  width: 175px;
  margin-right: 15px;
}

.side-panel .logo-text {
  display: inline-block;
  color: #4E4B66;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: 33px;
  letter-spacing: 0em;
  text-align: left;
}

@media only screen and (max-width: 600px) {
  .side-panel {
    display: block; 
    width: 100%;
    height: fit-content;
    padding-bottom: 24px;
    opacity: 1;
    background: inherit;
    z-index: 2;
  }

  .side-panel .logo-container {
    margin: 24px 0 0 0;
    padding-left: 2rem;
    padding-right: 2rem;
    width: 100%;
  }

  .side-panel .logo-text {
    color: #4E4B66;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: 33px;
    letter-spacing: 0em;
    text-align: left;
  }
}

