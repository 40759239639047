.mt-0 {
    margin-top: 0rem !important;
  }

  .mb-0 {
    margin-bottom: 0rem !important;
  }

  .mr-0 {
    margin-right: 0rem !important;
  }

  .ml-0 {
    margin-left: 0rem !important;
  }

  .pt-0 {
    padding-top: 0rem !important;
  }

  .pb-0 {
    padding-bottom: 0rem !important;
  }

  .pr-0 {
    padding-right: 0rem !important;
  }

  .pl-0 {
    padding-left: 0rem !important;
  }


  .mt-1 {
    margin-top: 1rem !important;
  }

  .mb-1 {
    margin-bottom: 1rem !important;
  }

  .mr-1 {
    margin-right: 1rem !important;
  }

  .ml-1 {
    margin-left: 1rem !important;
  }

  .pt-1 {
    padding-top: 1rem !important;
  }

  .pb-1 {
    padding-bottom: 1rem !important;
  }

  .pr-1 {
    padding-right: 1rem !important;
  }

  .pl-1 {
    padding-left: 1rem !important;
  }


  .mt-2 {
    margin-top: 2rem !important;
  }

  .mb-2 {
    margin-bottom: 2rem !important;
  }

  .mr-2 {
    margin-right: 2rem !important;
  }

  .ml-2 {
    margin-left: 2rem !important;
  }

  .pt-2 {
    padding-top: 2rem !important;
  }

  .pb-2 {
    padding-bottom: 2rem !important;
  }

  .pr-2 {
    padding-right: 2rem !important;
  }

  .pl-2 {
    padding-left: 2rem !important;
  }


  .mt-3 {
    margin-top: 3rem !important;
  }

  .mb-3 {
    margin-bottom: 3rem !important;
  }

  .mr-3 {
    margin-right: 3rem !important;
  }

  .ml-3 {
    margin-left: 3rem !important;
  }

  .pt-3 {
    padding-top: 3rem !important;
  }

  .pb-3 {
    padding-bottom: 3rem !important;
  }

  .pr-3 {
    padding-right: 3rem !important;
  }

  .pl-3 {
    padding-left: 3rem !important;
  }


  .mt-4 {
    margin-top: 4rem !important;
  }

  .mb-4 {
    margin-bottom: 4rem !important;
  }

  .mr-4 {
    margin-right: 4rem !important;
  }

  .ml-4 {
    margin-left: 4rem !important;
  }

  .pt-4 {
    padding-top: 4rem !important;
  }

  .pb-4 {
    padding-bottom: 4rem !important;
  }

  .pr-4 {
    padding-right: 4rem !important;
  }

  .pl-4 {
    padding-left: 4rem !important;
  }


  .mt-5 {
    margin-top: 5rem !important;
  }

  .mb-5 {
    margin-bottom: 5rem !important;
  }

  .mr-5 {
    margin-right: 5rem !important;
  }

  .ml-5 {
    margin-left: 5rem !important;
  }

  .pt-5 {
    padding-top: 5rem !important;
  }

  .pb-5 {
    padding-bottom: 5rem !important;
  }

  .pr-5 {
    padding-right: 5rem !important;
  }

  .pl-5 {
    padding-left: 5rem !important;
  }


  .mt-6 {
    margin-top: 6rem !important;
  }

  .mb-6 {
    margin-bottom: 6rem !important;
  }

  .mr-6 {
    margin-right: 6rem !important;
  }

  .ml-6 {
    margin-left: 6rem !important;
  }

  .pt-6 {
    padding-top: 6rem !important;
  }

  .pb-6 {
    padding-bottom: 6rem !important;
  }

  .pr-6 {
    padding-right: 6rem !important;
  }

  .pl-6 {
    padding-left: 6rem !important;
  }


  .mt-7 {
    margin-top: 7rem !important;
  }

  .mb-7 {
    margin-bottom: 7rem !important;
  }

  .mr-7 {
    margin-right: 7rem !important;
  }

  .ml-7 {
    margin-left: 7rem !important;
  }

  .pt-7 {
    padding-top: 7rem !important;
  }

  .pb-7 {
    padding-bottom: 7rem !important;
  }

  .pr-7 {
    padding-right: 7rem !important;
  }

  .pl-7 {
    padding-left: 7rem !important;
  }


  .mt-8 {
    margin-top: 8rem !important;
  }

  .mb-8 {
    margin-bottom: 8rem !important;
  }

  .mr-8 {
    margin-right: 8rem !important;
  }

  .ml-8 {
    margin-left: 8rem !important;
  }

  .pt-8 {
    padding-top: 8rem !important;
  }

  .pb-8 {
    padding-bottom: 8rem !important;
  }

  .pr-8 {
    padding-right: 8rem !important;
  }

  .pl-8 {
    padding-left: 8rem !important;
  }


  .mt-9 {
    margin-top: 9rem !important;
  }

  .mb-9 {
    margin-bottom: 9rem !important;
  }

  .mr-9 {
    margin-right: 9rem !important;
  }

  .ml-9 {
    margin-left: 9rem !important;
  }

  .pt-9 {
    padding-top: 9rem !important;
  }

  .pb-9 {
    padding-bottom: 9rem !important;
  }

  .pr-9 {
    padding-right: 9rem !important;
  }

  .pl-9 {
    padding-left: 9rem !important;
  }


  .mt-10 {
    margin-top: 10rem !important;
  }

  .mb-10 {
    margin-bottom: 10rem !important;
  }

  .mr-10 {
    margin-right: 10rem !important;
  }

  .ml-10 {
    margin-left: 10rem !important;
  }

  .pt-10 {
    padding-top: 10rem !important;
  }

  .pb-10 {
    padding-bottom: 10rem !important;
  }

  .pr-10 {
    padding-right: 10rem !important;
  }

  .pl-10 {
    padding-left: 10rem !important;
  }


  .mt-11 {
    margin-top: 11rem !important;
  }

  .mb-11 {
    margin-bottom: 11rem !important;
  }

  .mr-11 {
    margin-right: 11rem !important;
  }

  .ml-11 {
    margin-left: 11rem !important;
  }

  .pt-11 {
    padding-top: 11rem !important;
  }

  .pb-11 {
    padding-bottom: 11rem !important;
  }

  .pr-11 {
    padding-right: 11rem !important;
  }

  .pl-11 {
    padding-left: 11rem !important;
  }


  .mt-12 {
    margin-top: 12rem !important;
  }

  .mb-12 {
    margin-bottom: 12rem !important;
  }

  .mr-12 {
    margin-right: 12rem !important;
  }

  .ml-12 {
    margin-left: 12rem !important;
  }

  .pt-12 {
    padding-top: 12rem !important;
  }

  .pb-12 {
    padding-bottom: 12rem !important;
  }

  .pr-12 {
    padding-right: 12rem !important;
  }

  .pl-12 {
    padding-left: 12rem !important;
  }


  .mt-13 {
    margin-top: 13rem !important;
  }

  .mb-13 {
    margin-bottom: 13rem !important;
  }

  .mr-13 {
    margin-right: 13rem !important;
  }

  .ml-13 {
    margin-left: 13rem !important;
  }

  .pt-13 {
    padding-top: 13rem !important;
  }

  .pb-13 {
    padding-bottom: 13rem !important;
  }

  .pr-13 {
    padding-right: 13rem !important;
  }

  .pl-13 {
    padding-left: 13rem !important;
  }


  .mt-14 {
    margin-top: 14rem !important;
  }

  .mb-14 {
    margin-bottom: 14rem !important;
  }

  .mr-14 {
    margin-right: 14rem !important;
  }

  .ml-14 {
    margin-left: 14rem !important;
  }

  .pt-14 {
    padding-top: 14rem !important;
  }

  .pb-14 {
    padding-bottom: 14rem !important;
  }

  .pr-14 {
    padding-right: 14rem !important;
  }

  .pl-14 {
    padding-left: 14rem !important;
  }


  .mt-15 {
    margin-top: 15rem !important;
  }

  .mb-15 {
    margin-bottom: 15rem !important;
  }

  .mr-15 {
    margin-right: 15rem !important;
  }

  .ml-15 {
    margin-left: 15rem !important;
  }

  .pt-15 {
    padding-top: 15rem !important;
  }

  .pb-15 {
    padding-bottom: 15rem !important;
  }

  .pr-15 {
    padding-right: 15rem !important;
  }

  .pl-15 {
    padding-left: 15rem !important;
  }


  .mt-16 {
    margin-top: 16rem !important;
  }

  .mb-16 {
    margin-bottom: 16rem !important;
  }

  .mr-16 {
    margin-right: 16rem !important;
  }

  .ml-16 {
    margin-left: 16rem !important;
  }

  .pt-16 {
    padding-top: 16rem !important;
  }

  .pb-16 {
    padding-bottom: 16rem !important;
  }

  .pr-16 {
    padding-right: 16rem !important;
  }

  .pl-16 {
    padding-left: 16rem !important;
  }


  .mt-17 {
    margin-top: 17rem !important;
  }

  .mb-17 {
    margin-bottom: 17rem !important;
  }

  .mr-17 {
    margin-right: 17rem !important;
  }

  .ml-17 {
    margin-left: 17rem !important;
  }

  .pt-17 {
    padding-top: 17rem !important;
  }

  .pb-17 {
    padding-bottom: 17rem !important;
  }

  .pr-17 {
    padding-right: 17rem !important;
  }

  .pl-17 {
    padding-left: 17rem !important;
  }


  .mt-18 {
    margin-top: 18rem !important;
  }

  .mb-18 {
    margin-bottom: 18rem !important;
  }

  .mr-18 {
    margin-right: 18rem !important;
  }

  .ml-18 {
    margin-left: 18rem !important;
  }

  .pt-18 {
    padding-top: 18rem !important;
  }

  .pb-18 {
    padding-bottom: 18rem !important;
  }

  .pr-18 {
    padding-right: 18rem !important;
  }

  .pl-18 {
    padding-left: 18rem !important;
  }


  .mt-19 {
    margin-top: 19rem !important;
  }

  .mb-19 {
    margin-bottom: 19rem !important;
  }

  .mr-19 {
    margin-right: 19rem !important;
  }

  .ml-19 {
    margin-left: 19rem !important;
  }

  .pt-19 {
    padding-top: 19rem !important;
  }

  .pb-19 {
    padding-bottom: 19rem !important;
  }

  .pr-19 {
    padding-right: 19rem !important;
  }

  .pl-19 {
    padding-left: 19rem !important;
  }