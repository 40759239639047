.text-input-container {
  display: block;
  width: 100%;
  margin-bottom: 1.5rem;
}

.text-small {
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.75px;
  text-align: left;     /* Opera/IE 8+ */
}

.text-input {
  background: #F7F7FC;
  text-indent: 10px;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box;    /* Firefox, other Gecko */
  box-sizing: border-box;    
  border: none;
  border-radius: 0.5rem;
  height: 3.5rem;
  padding: 1.25rem;
  width: 100%;
}

.text-input:focus {
  outline: none;
}

.text-input.error {
  box-shadow: 0 0 0 2px #EF5DA8;
}

.helper-text {
  margin: 1rem;
}

.helper-text.error {
  color: #EF5DA8;
}
