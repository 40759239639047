@import url(https://fonts.googleapis.com/css2?family=Poppins&display=swap);
body {
  background-color: #f9f9f9;
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media only screen and (min-width: 960px) and (max-width: 1145px) {
  .search-tile {
    height: 17.5rem !important;
  }

  .search-tile .image {
    height: 110px !important;
  }
}

.sidebar-placeholder {
  width: 250px;
  float:left;
  height: 100vh;
}

.sidebar {
  width: 250px;
  padding: 20px;
  text-align: center;
  position: fixed;
  bottom:0px;
  top:0px;
  color: #4E4B66;
  font-weight: bold;
  
}

/*.content {
  width: calc(100% - 250px);
  float:left;
}*/

.content {
  width: 100%;
  float:left;
}

.bg-green-1 {
  background-color:#32c588
}

.color-green-1 {
  color:#5fcf95
}

.bg-green-2 {
  background-color:#00c996
}

.color-green-2 {
  color:#00c996
}

.bg-green-3 {
  background-color: #00af9b;
}

.color-green-3 {
  color: #00af9b;
}

.bg-green-4 {
  background-color: #348d84;
}

.color-green-4 {
  color: #348d84;
}

.sidebar-bg-color {
  background-color: rgba(248, 249, 255, 0.45)
}

.shadow, .card-profile-image img, .sidebar {
  box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15) !important;
}

.upk-card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 0.375rem;
  min-height: 100px;
}

.bg-card-secondary {
  background-color: #f7fafc !important;
}

.upk-card .header {
  padding: 1.25rem 1.5rem;
  margin-bottom: 0;
  background-color: #fff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  justify-content: space-between;
  display: flex;
}

.upk-card .header:first-child {
  border-radius: calc(0.375rem - 1px) calc(0.375rem - 1px) 0 0;
}

.upk-card .body {
  flex: 1 1 auto;
  padding: 1.5rem;
}

.heading-small {
  padding-top: .25rem;
  padding-bottom: 1rem;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: .04em;
}

.heading-medium, .heading-small {
  color: #4E4B66;
  letter-spacing: 0.75px;
  font-weight: 600;
}

.heading-medium {
  font-size: 22px;
}

.heading-small {
  font-size: 18px;
}

.text-muted {
  color: #8898aa;
}


.tab-btn {
  position: relative;
  text-transform: none;
  transition: all 0.15s ease;
  letter-spacing: 0.025em;
  font-size: 0.875rem;
  will-change: transform;
}

.tab-btn-sm, .tab-btn-group-sm > .tab-btn {
  padding: 0.25rem 0.5rem !important;
  font-size: 0.75rem !important;
  line-height: 1.5 !important;
  border-radius: 0.375rem !important;
}

.tab-btn {
  outline: none;
  display: inline-block;
  font-weight: 600;
  color: #525f7f;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.625rem 1.25rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.375rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.tab-btn-success {
  color: #fff;
  background-color: #2dce89;
  border-color: #2dce89;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
}

.tab-btn-primary {
  color: #fff;
  background-color:#3784d8;
  border-color: #3784d8;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
}

.tab-btn-outline {
  color: black;
  border-color:black;
  /*-webkit-box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);*/
}

.tab-btn-danger {
  color: #fff;
  background-color:#e44756;
  border-color: #e44756;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
}

.bg-success-secondary {
  background-color: #4c9e74;
  border-color: none;
}

.layout {
  overflow-y: hidden;
}

.svc-mapping-row {
  margin: 1rem;
  padding: 1rem;
  background-color: white;
  border-radius: 0.2rem;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.search-tile {
  background: #FAFFFC;
  border: 1px solid #DDFFEB;
  border-radius: 12px;
  height: 15.5rem;
}

.search-tile .image {
  height: 120px;
  width: 85%; 
  margin: 0 auto;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 16px;
}

.search-tile p {
  font-size: 14px;
  color: #7C7C7C;
}

.search-tile:hover {
  cursor: pointer;
}

.upk-form .header {
  padding-bottom: 1.25rem;
  margin-bottom: 2rem;
  background-color: #fff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  justify-content: space-between;
  display: flex;
}

.upk-form .title {
  color: #4A4A49;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
}

.provider-header .cover-image {
  height: 175px;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 10px;
  opacity: .6;
}

.provider-header .title-banner {
  height: 75px;
  width: 100%;
  background-color: white;
  opacity: .5;
  margin: -75px 0 75px 0;
  border-radius: 0 0 10px 10px;
  z-index: 1;
  position: absolute;
}

.provider-header .title {
  font-weight: 600;
  font-size: 26px;
  margin: -60px 0 60px 1.5rem;
  z-index: 2;
  position: absolute;
  color: #262338;
  opacity: .9;
  letter-spacing: 1px;
}

.border {
  border: 1px solid #EFF0F6;
  border-radius: 8px;
  padding: 2.5rem 2rem;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

.mt-0 {
    margin-top: 0rem !important;
  }

  .mb-0 {
    margin-bottom: 0rem !important;
  }

  .mr-0 {
    margin-right: 0rem !important;
  }

  .ml-0 {
    margin-left: 0rem !important;
  }

  .pt-0 {
    padding-top: 0rem !important;
  }

  .pb-0 {
    padding-bottom: 0rem !important;
  }

  .pr-0 {
    padding-right: 0rem !important;
  }

  .pl-0 {
    padding-left: 0rem !important;
  }


  .mt-1 {
    margin-top: 1rem !important;
  }

  .mb-1 {
    margin-bottom: 1rem !important;
  }

  .mr-1 {
    margin-right: 1rem !important;
  }

  .ml-1 {
    margin-left: 1rem !important;
  }

  .pt-1 {
    padding-top: 1rem !important;
  }

  .pb-1 {
    padding-bottom: 1rem !important;
  }

  .pr-1 {
    padding-right: 1rem !important;
  }

  .pl-1 {
    padding-left: 1rem !important;
  }


  .mt-2 {
    margin-top: 2rem !important;
  }

  .mb-2 {
    margin-bottom: 2rem !important;
  }

  .mr-2 {
    margin-right: 2rem !important;
  }

  .ml-2 {
    margin-left: 2rem !important;
  }

  .pt-2 {
    padding-top: 2rem !important;
  }

  .pb-2 {
    padding-bottom: 2rem !important;
  }

  .pr-2 {
    padding-right: 2rem !important;
  }

  .pl-2 {
    padding-left: 2rem !important;
  }


  .mt-3 {
    margin-top: 3rem !important;
  }

  .mb-3 {
    margin-bottom: 3rem !important;
  }

  .mr-3 {
    margin-right: 3rem !important;
  }

  .ml-3 {
    margin-left: 3rem !important;
  }

  .pt-3 {
    padding-top: 3rem !important;
  }

  .pb-3 {
    padding-bottom: 3rem !important;
  }

  .pr-3 {
    padding-right: 3rem !important;
  }

  .pl-3 {
    padding-left: 3rem !important;
  }


  .mt-4 {
    margin-top: 4rem !important;
  }

  .mb-4 {
    margin-bottom: 4rem !important;
  }

  .mr-4 {
    margin-right: 4rem !important;
  }

  .ml-4 {
    margin-left: 4rem !important;
  }

  .pt-4 {
    padding-top: 4rem !important;
  }

  .pb-4 {
    padding-bottom: 4rem !important;
  }

  .pr-4 {
    padding-right: 4rem !important;
  }

  .pl-4 {
    padding-left: 4rem !important;
  }


  .mt-5 {
    margin-top: 5rem !important;
  }

  .mb-5 {
    margin-bottom: 5rem !important;
  }

  .mr-5 {
    margin-right: 5rem !important;
  }

  .ml-5 {
    margin-left: 5rem !important;
  }

  .pt-5 {
    padding-top: 5rem !important;
  }

  .pb-5 {
    padding-bottom: 5rem !important;
  }

  .pr-5 {
    padding-right: 5rem !important;
  }

  .pl-5 {
    padding-left: 5rem !important;
  }


  .mt-6 {
    margin-top: 6rem !important;
  }

  .mb-6 {
    margin-bottom: 6rem !important;
  }

  .mr-6 {
    margin-right: 6rem !important;
  }

  .ml-6 {
    margin-left: 6rem !important;
  }

  .pt-6 {
    padding-top: 6rem !important;
  }

  .pb-6 {
    padding-bottom: 6rem !important;
  }

  .pr-6 {
    padding-right: 6rem !important;
  }

  .pl-6 {
    padding-left: 6rem !important;
  }


  .mt-7 {
    margin-top: 7rem !important;
  }

  .mb-7 {
    margin-bottom: 7rem !important;
  }

  .mr-7 {
    margin-right: 7rem !important;
  }

  .ml-7 {
    margin-left: 7rem !important;
  }

  .pt-7 {
    padding-top: 7rem !important;
  }

  .pb-7 {
    padding-bottom: 7rem !important;
  }

  .pr-7 {
    padding-right: 7rem !important;
  }

  .pl-7 {
    padding-left: 7rem !important;
  }


  .mt-8 {
    margin-top: 8rem !important;
  }

  .mb-8 {
    margin-bottom: 8rem !important;
  }

  .mr-8 {
    margin-right: 8rem !important;
  }

  .ml-8 {
    margin-left: 8rem !important;
  }

  .pt-8 {
    padding-top: 8rem !important;
  }

  .pb-8 {
    padding-bottom: 8rem !important;
  }

  .pr-8 {
    padding-right: 8rem !important;
  }

  .pl-8 {
    padding-left: 8rem !important;
  }


  .mt-9 {
    margin-top: 9rem !important;
  }

  .mb-9 {
    margin-bottom: 9rem !important;
  }

  .mr-9 {
    margin-right: 9rem !important;
  }

  .ml-9 {
    margin-left: 9rem !important;
  }

  .pt-9 {
    padding-top: 9rem !important;
  }

  .pb-9 {
    padding-bottom: 9rem !important;
  }

  .pr-9 {
    padding-right: 9rem !important;
  }

  .pl-9 {
    padding-left: 9rem !important;
  }


  .mt-10 {
    margin-top: 10rem !important;
  }

  .mb-10 {
    margin-bottom: 10rem !important;
  }

  .mr-10 {
    margin-right: 10rem !important;
  }

  .ml-10 {
    margin-left: 10rem !important;
  }

  .pt-10 {
    padding-top: 10rem !important;
  }

  .pb-10 {
    padding-bottom: 10rem !important;
  }

  .pr-10 {
    padding-right: 10rem !important;
  }

  .pl-10 {
    padding-left: 10rem !important;
  }


  .mt-11 {
    margin-top: 11rem !important;
  }

  .mb-11 {
    margin-bottom: 11rem !important;
  }

  .mr-11 {
    margin-right: 11rem !important;
  }

  .ml-11 {
    margin-left: 11rem !important;
  }

  .pt-11 {
    padding-top: 11rem !important;
  }

  .pb-11 {
    padding-bottom: 11rem !important;
  }

  .pr-11 {
    padding-right: 11rem !important;
  }

  .pl-11 {
    padding-left: 11rem !important;
  }


  .mt-12 {
    margin-top: 12rem !important;
  }

  .mb-12 {
    margin-bottom: 12rem !important;
  }

  .mr-12 {
    margin-right: 12rem !important;
  }

  .ml-12 {
    margin-left: 12rem !important;
  }

  .pt-12 {
    padding-top: 12rem !important;
  }

  .pb-12 {
    padding-bottom: 12rem !important;
  }

  .pr-12 {
    padding-right: 12rem !important;
  }

  .pl-12 {
    padding-left: 12rem !important;
  }


  .mt-13 {
    margin-top: 13rem !important;
  }

  .mb-13 {
    margin-bottom: 13rem !important;
  }

  .mr-13 {
    margin-right: 13rem !important;
  }

  .ml-13 {
    margin-left: 13rem !important;
  }

  .pt-13 {
    padding-top: 13rem !important;
  }

  .pb-13 {
    padding-bottom: 13rem !important;
  }

  .pr-13 {
    padding-right: 13rem !important;
  }

  .pl-13 {
    padding-left: 13rem !important;
  }


  .mt-14 {
    margin-top: 14rem !important;
  }

  .mb-14 {
    margin-bottom: 14rem !important;
  }

  .mr-14 {
    margin-right: 14rem !important;
  }

  .ml-14 {
    margin-left: 14rem !important;
  }

  .pt-14 {
    padding-top: 14rem !important;
  }

  .pb-14 {
    padding-bottom: 14rem !important;
  }

  .pr-14 {
    padding-right: 14rem !important;
  }

  .pl-14 {
    padding-left: 14rem !important;
  }


  .mt-15 {
    margin-top: 15rem !important;
  }

  .mb-15 {
    margin-bottom: 15rem !important;
  }

  .mr-15 {
    margin-right: 15rem !important;
  }

  .ml-15 {
    margin-left: 15rem !important;
  }

  .pt-15 {
    padding-top: 15rem !important;
  }

  .pb-15 {
    padding-bottom: 15rem !important;
  }

  .pr-15 {
    padding-right: 15rem !important;
  }

  .pl-15 {
    padding-left: 15rem !important;
  }


  .mt-16 {
    margin-top: 16rem !important;
  }

  .mb-16 {
    margin-bottom: 16rem !important;
  }

  .mr-16 {
    margin-right: 16rem !important;
  }

  .ml-16 {
    margin-left: 16rem !important;
  }

  .pt-16 {
    padding-top: 16rem !important;
  }

  .pb-16 {
    padding-bottom: 16rem !important;
  }

  .pr-16 {
    padding-right: 16rem !important;
  }

  .pl-16 {
    padding-left: 16rem !important;
  }


  .mt-17 {
    margin-top: 17rem !important;
  }

  .mb-17 {
    margin-bottom: 17rem !important;
  }

  .mr-17 {
    margin-right: 17rem !important;
  }

  .ml-17 {
    margin-left: 17rem !important;
  }

  .pt-17 {
    padding-top: 17rem !important;
  }

  .pb-17 {
    padding-bottom: 17rem !important;
  }

  .pr-17 {
    padding-right: 17rem !important;
  }

  .pl-17 {
    padding-left: 17rem !important;
  }


  .mt-18 {
    margin-top: 18rem !important;
  }

  .mb-18 {
    margin-bottom: 18rem !important;
  }

  .mr-18 {
    margin-right: 18rem !important;
  }

  .ml-18 {
    margin-left: 18rem !important;
  }

  .pt-18 {
    padding-top: 18rem !important;
  }

  .pb-18 {
    padding-bottom: 18rem !important;
  }

  .pr-18 {
    padding-right: 18rem !important;
  }

  .pl-18 {
    padding-left: 18rem !important;
  }


  .mt-19 {
    margin-top: 19rem !important;
  }

  .mb-19 {
    margin-bottom: 19rem !important;
  }

  .mr-19 {
    margin-right: 19rem !important;
  }

  .ml-19 {
    margin-left: 19rem !important;
  }

  .pt-19 {
    padding-top: 19rem !important;
  }

  .pb-19 {
    padding-bottom: 19rem !important;
  }

  .pr-19 {
    padding-right: 19rem !important;
  }

  .pl-19 {
    padding-left: 19rem !important;
  }
/* #### Generated By: http://www.cufonfonts.com #### */

    @font-face {
    font-family: 'SF Pro Display Regular';
    font-style: normal;
    font-weight: normal;
    src: local('SF Pro Display Regular'), url(/static/media/SFPRODISPLAYREGULAR.9101ead9.woff) format('woff');
    }
    

    @font-face {
    font-family: 'SF Pro Display Ultralight Italic';
    font-style: normal;
    font-weight: normal;
    src: local('SF Pro Display Ultralight Italic'), url(/static/media/SFPRODISPLAYULTRALIGHTITALIC.5c25e384.woff) format('woff');
    }
    

    @font-face {
    font-family: 'SF Pro Display Thin Italic';
    font-style: normal;
    font-weight: normal;
    src: local('SF Pro Display Thin Italic'), url(/static/media/SFPRODISPLAYTHINITALIC.cb9bfbd5.woff) format('woff');
    }
    

    @font-face {
    font-family: 'SF Pro Display Light Italic';
    font-style: normal;
    font-weight: normal;
    src: local('SF Pro Display Light Italic'), url(/static/media/SFPRODISPLAYLIGHTITALIC.fde32acb.woff) format('woff');
    }
    

    @font-face {
    font-family: 'SF Pro Display Medium';
    font-style: normal;
    font-weight: normal;
    src: local('SF Pro Display Medium'), url(/static/media/SFPRODISPLAYMEDIUM.6b0b5780.woff) format('woff');
    }
    

    @font-face {
    font-family: 'SF Pro Display Semibold Italic';
    font-style: normal;
    font-weight: normal;
    src: local('SF Pro Display Semibold Italic'), url(/static/media/SFPRODISPLAYSEMIBOLDITALIC.10d75a92.woff) format('woff');
    }
    

    @font-face {
    font-family: 'SF Pro Display Bold';
    font-style: normal;
    font-weight: normal;
    src: local('SF Pro Display Bold'), url(/static/media/SFPRODISPLAYBOLD.5a6d43d3.woff) format('woff');
    }
    

    @font-face {
    font-family: 'SF Pro Display Heavy Italic';
    font-style: normal;
    font-weight: normal;
    src: local('SF Pro Display Heavy Italic'), url(/static/media/SFPRODISPLAYHEAVYITALIC.710ad197.woff) format('woff');
    }
    

    @font-face {
    font-family: 'SF Pro Display Black Italic';
    font-style: normal;
    font-weight: normal;
    src: local('SF Pro Display Black Italic'), url(/static/media/SFPRODISPLAYBLACKITALIC.a4f7f321.woff) format('woff');
    }
.login-button {
  margin-top: 120px;
}

.button-container {
  display: block;
  width: 184px;
  text-align: center;
  height: 2.5rem;
}

.button-container button {
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 0.75px;
  border: none;
  height: 2.5rem;
  width: 100%;
  border-radius: 1.5rem;
  color: var(--main-button-color);
}

.button-container.fullWidth {
  width: 100%;
}

.button-container button.outlined {
  box-shadow: 0 0 0 1.5px;
}

.button-container button.white {
  background: white;
}

.button-container button.white.active {
  background: var(--main-button-color);
  color: white !important;
}

.button-container button.primary.active {
  background: #00AF9B;
  opacity: 1;
}

.button-container button.primary {
  background: #00AF9B;
  opacity: 0.6;
}

.button-container button.secondary {
  background: #A0A3BD;
  opacity: 0.5;
}

.button-container button.secondary.active {
  opacity: 1;
}

.button-container button.transparent {
  background: inherit;
}

.button-container button.transparent.active {
  box-shadow: 0 0 0 1px;
}

.button-container button.black {
  background: #000;
  opacity: 0.5;
}

.button-container button.black.active {
  opacity: 1;
}

.button-container button:disabled {
  opacity: .5;
  background-color: #4E4B66;
}

.onboarding-container {
  background-color: #FFFFFF;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  height: 100vh;
  width: 100vw;
  overflow-y: scroll;
  position: relative;
}

.onboarding-container h1 {
  color: #4A4A49;
  font-weight: bold;
  font-size: 24px;
  line-height: 35px;
}

.onboarding-container h1 > em {
  font-style: normal;
  color: #00C996;
}

.onboarding-container h2 {
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
}

.onboarding-container p {
  margin-bottom: 28px;
  font-family: 'SF Pro Display Regular';
}

.onboarding-container .content-container {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 65%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding-bottom: 10%;
}

.onboarding-container .content {
  margin: auto;
  margin-top: 200px;
  text-align: left;
  max-width: 620px;
  width: 100%;
  padding: 50px 50px;

  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.02em;
  color: #6E7191;
}

.onboarding-container .content.less-top-margin {
  margin-top: 150px;
}

.onboarding-container .actions {
  position: absolute;
  left: 0;
  bottom: 0;
  margin-top: 10%;
  margin-bottom: 10%;
  padding: 0 20%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.onboarding-container .actions .button-container {
  width: 100%;
  max-width: 184px;
}

.onboarding-container .actions .button-container.next {
  float: right;
}

@media only screen and (max-width: 600px) {
  .onboarding-container {
    flex-direction: column;
  }

  .onboarding-container .content-container {
    width: 100%;
  }

  .onboarding-container .content {
    padding: 1rem;
    margin-top: 2rem;
    width: 100%;
  }

  .onboarding-container .content.less-top-margin {
    margin-top: 2rem;
  }

  .onboarding-container .actions {
    position: relative;
    bottom: 0;
    margin-bottom: 10%;
    width: 100%;
  }

  .actions .button-container {
    max-width: 50%;
    width: 150px;
  }
}

.side-panel {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 35%;
  background-color: #F8F9FF66;
  height: 100%;
}

.side-panel .logo-container {
  margin-top: 81px;
  margin-left: 64px;
  margin-bottom: 36px;
}

.side-panel .logo {
  width: 175px;
  margin-right: 15px;
}

.side-panel .logo-text {
  display: inline-block;
  color: #4E4B66;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: 33px;
  letter-spacing: 0em;
  text-align: left;
}

@media only screen and (max-width: 600px) {
  .side-panel {
    display: block; 
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding-bottom: 24px;
    opacity: 1;
    background: inherit;
    z-index: 2;
  }

  .side-panel .logo-container {
    margin: 24px 0 0 0;
    padding-left: 2rem;
    padding-right: 2rem;
    width: 100%;
  }

  .side-panel .logo-text {
    color: #4E4B66;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: 33px;
    letter-spacing: 0em;
    text-align: left;
  }
}


.side-panel-image-container {
  margin: auto;
  margin-top: 30%;
  width: 100%;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.side-panel-image-container .image {
  width: 100%;
  max-width: 400px;
}

.side-panel-image-container .caption {
  margin-top: 24px;
  max-width: 400px;
  
  font-family: 'SF Pro Display Regular';
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  text-align: center;

  /* Cool Grey/Grey 400 */
  color: #A0A3BD;
}

@media only screen and (max-width: 600px) {
  .side-panel-image-container {
    display: none;
  }
}

.text-input-container {
  display: block;
  width: 100%;
  margin-bottom: 1.5rem;
}

.text-small {
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.75px;
  text-align: left;     /* Opera/IE 8+ */
}

.text-input {
  background: #F7F7FC;
  text-indent: 10px; /* Safari/Chrome, other WebKit */    /* Firefox, other Gecko */
  box-sizing: border-box;    
  border: none;
  border-radius: 0.5rem;
  height: 3.5rem;
  padding: 1.25rem;
  width: 100%;
}

.text-input:focus {
  outline: none;
}

.text-input.error {
  box-shadow: 0 0 0 2px #EF5DA8;
}

.helper-text {
  margin: 1rem;
}

.helper-text.error {
  color: #EF5DA8;
}

.input-fields {
  height: 300px;
}

.selection-buttons {
  width: 80%;
  margin: auto;
}

.selection-buttons .button-container {
  margin: 24px 0;
}

.input-fields {
  height: 300px;
}

.content h3 {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 1.6rem;
  letter-spacing: 0.75px;
}

.address div {
  margin: 0 0 0.75rem 0;
}

.inline {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 3.5rem;
}

.inline .text-input-container {
  margin-bottom: 0;
  width: 33%;
}

.inline .text-input-container:not(:last-child) {
  margin-right: 1rem;
}

.inline .text-input-container .text-input {
  width: 100%;
}

.multiline-input {
  background: #F8F9FF;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 1.5rem;
  border-radius: 8px;
  border: none;
  outline: none;

  width: 100%;
  height: 144px;

  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.75px;
  text-align: left;  

  /* Inside Auto Layout */
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 8px 0px;
}

.provider-service-item-mapping {
  margin-top: 5.5rem;
}

.content.wide {
  max-width: none;
}

.stepper {
  position: absolute;
  top: 5rem;
  right: 4rem;
}

.stepper .svg-circle {
  position: relative;
  top: -3px;
  width: 32px;
  height: 32px;
  margin: -8px -8px -8px 12px;
}

.stepper .svg-circle rect {
  fill: none;
}

.stepper .circle {
  height: 1rem;
  width: 1rem;
  border-radius: 50%;
  margin-left: 20px;
  display: inline-block;
  background-color: white;
  color: #A0A3BD;
  box-shadow: 0 0 0 1px;
  position: relative;
}

.stepper .circle.active {
  color: #00AF9B
}

.stepper .circle.checked+label {
    position: absolute;
    top: 0.1rem;
    right: 0;
    font-size: 1rem;
    line-height: 0.8;
}

@media only screen and (max-width: 600px) {
  .stepper {
    position: relative;
    top: 2rem;
    margin: auto;
    left: 0;
    z-index: 1;
  }
}

.app-preview-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 80%;
  max-width: 360px;
  margin: 0 auto;
}

.app-preview-container .grey-background {
  background: #6e71911c;
  height: 60vh;
  width: 100%;
  border-radius: 8px;
  overflow-y: hidden;
}

.app-preview {
  height: 100%;
  width: 90%;
  max-width: 325px;
  margin: 0 auto;
  border-radius: 1rem;
  background: #FFFFFF;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.app-preview h4 {
  text-align: center;
  font-family: Poppins;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  color: #6E7191;
}

.app-preview h6 {
  text-align: center;
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
}

.app-preview p {
  color: #6E7191;
}

.app-preview hr {
  width: 80%;
  margin: 0 auto 1rem;
}

.app-preview .mockh6 {
  height: 1rem;
  width: 40%;
  background: #6e71913b;
  border-radius: .25rem;
  margin-bottom: 1rem;
}

.app-preview .mockp {
  height: 1rem;
  width: 100%;
  background: #6e71911f;
  border-radius: .25rem;
}

.cover-photo-preview {
  background: #6e71913b;
  width: 100%;
  height: 180px;
  border-bottom-left-radius: 100px;
  border-bottom-right-radius: 100px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
  object-fit: cover;
}

/* app preview looks funky when screen is too small */
@media only screen and (max-width: 800px) {
  .app-preview-container {
    display: none;
  }
}

.image-uploader {
  width: 100%;
  height: 142px;

  background: rgba(247, 247, 252, 0.3);
  
  border: 1px dashed #6E7191;
  box-sizing: border-box;
  border-radius: 8px;
  
  /* Inside Auto Layout */
  display: flex;
  cursor: pointer;
  margin-bottom: 1rem;
  text-align: center;
}

.image-uploader.filled {
  border: none;
}

.image-uploader.logo {
  width: 142px;
}

.image-uploader img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.image-uploader span {
  margin-top: .5rem;
  font-size: 1rem;
  font-weight: 500;
}

.camera-icon path {
  stroke: #D9DBE9;
}

.camera-icon circle {
  stroke: #D9DBE9;
}

.banner {
  background-color: #ffffff;
  display: flex;
  border-radius: 4px;
  padding: 0.8rem;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15) !important;
  border-left: 11px solid #00c996;
  font-weight: 600;
  color: #4e4b66;
}

.overview-container {
  color: #4e4b66;
  display: flex;
  width: 100%;
}

.overview-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.provider-info {
  flex-grow: 3;
}

.checklist-container {
  flex-grow: 1;
  min-width: 20rem;
}

.box {
  padding: 2.5rem;
}

.progress-chart-container {
  width: 100%;
  display: flex;
  justify-content: center;
  height: 130px;
}

.checklist {
  padding: 1.5rem 1rem;
}

.checklist .label {
  font-size: 15px;
}

.checklist .required {
  color: #a0a3bd;
  font-size: 14px;
}

.checklist .action {
  color: #000000;
}

.action a {
  text-decoration: underline;
  color: #000000;
}

.progress-chart canvas {
  width: 130px !important;
  height: 120px !important;
}

.progress-chart .background-bar {
  position: relative;
  z-index: 0;
}

.progress-bar {
  position: absolute;
  background-color: transparent;
  top: 0;
  left: 0;
  z-index: 1;
}

.chart-pointer-container {
  position: absolute;
  bottom: 25px;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0);
}

.chart-pointer-box {
  padding-right: 15px;
  -webkit-transform-origin: center;
          transform-origin: center;
  display: flex;
}

.provider-info .provider-cover {
  height: 175px;
}

.provider-info .cover-image {
  opacity: 1;
  border-radius: 0.375rem;
}

.provider-header .title-container {
  margin: -56px 0 60px 1.5rem;
  z-index: 2;
  position: absolute;
  display: flex;
  width: calc(100% - 3rem);
  justify-content: space-between;
  align-items: center;
}

.provider-info .provider-header .title {
  font-weight: 600;
  font-size: 26px;
  z-index: 2;
  position: relative;
  opacity: 1;
  font-weight: 600;
  font-size: 26px;
  color: #262338;
  letter-spacing: 1px;
  margin: 0;
}

.activity .stats {
  display: flex;
  flex-direction: row;
  margin-top: 2rem;
}

.activity .stats-number {
  font-size: 30px;
  font-weight: 700;
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}

.stats .revenue {
  border-left: 1px solid #d9dbe9;
  border-width: 1px;
  margin-left: 3rem;
  padding-left: 1rem;
}

.stats .lifetime {
  border-left: 1px solid #d9dbe9;
  color: #4e4b66;
}

.appointments-content {
  margin-top: 2rem;
}

.appointment {
  display: flex;
  flex-direction: row;
  border: 1px solid #eff0f6;
  border-radius: 8px;
  margin: 0.6rem 0;
}

.appointment .schedule {
  border-right: 1px solid #eff0f6;
  padding: 1rem 2rem 1rem 1rem;
  min-width: 9rem;
}

.appointment .date,
.appointment .time {
  font-weight: 600;
}

.booker-treatment {
  display: flex;
  flex-direction: row;
  padding: 1.5rem;
  flex: 1 1;
  align-items: center;
  justify-content: space-between;
}

.booker-treatment .content {
  display: flex;
  flex-direction: row;
}

.booker-treatment .content > div {
  flex: 1 1;
  width: 8rem;
}

.booker-treatment .content .treatment {
  flex: 2 1;
  width: 16rem;
}

.booker-treatment .sub {
  color: #6e7191;
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 0.3rem;
}

.booker-treatment .sub-content {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.booker-treatment .checkbox-col {
  display: flex;
  flex-direction: column;
}

.booker-treatment .checkbox {
  margin-top: -10px;
}

.booker-treatment .bullet {
  background-color: #eff0f6;
  text-align: center;
  border-radius: 6px;
  padding: 0 3px;
  cursor: pointer;
}

.appointments-content .past {
  color: #6e7191;
  margin-top: 1rem;
}

.overview-container .calendar {
  width: 130%;
}

.drawer-content {
  color: #4e4b66;
  position: relative;
}

.cancelled.drawer-content {
  color: #AAAAAA !important;
}

.drawer-content .title {
  font-size: 22px;
  font-weight: 600;
}

.drawer-content .close {
  position: absolute;
  top: -1rem;
  left: -0.5rem;
  cursor: pointer;
}

.drawer-content .section {
  margin: 3rem 0;
}

.drawer-content .section-appointment {
  margin-top: 3rem;
}

.drawer-content .subtitle {
  font-size: 16px;
}

.drawer-content .drawer-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 2rem 0;
}

.drawer-content .section-content {
  margin: 2rem 1rem;
}

.drawer-content .item {
  display: flex;
  justify-content: space-between;
}

.drawer-content .treatment {
  margin-top: 1rem;
}

.cancelled .info{
  color: #AAAAAA !important;
}

.drawer-content .info {
  color: #6e7191;
  margin: 0.3rem 0;
}
.drawer-content .info span {
  font-weight: 600;
}

.drawer-content .booker {
  display: flex;
  align-items: center;
}

.drawer-content .appointment-drawer {
  display: flex;
  align-items: flex-start;
  margin-bottom: 0;
}

.drawer-content .details {
  justify-content: space-between;
}

.cancelled .small-text{
  color: #AAAAAA !important;
}

.drawer-content .small-text {
  color: #6e7191;
  font-size: 13px;
  margin-top: 0.3rem;
}

.drawer-content .cancel-btn {
  display: flex;
  justify-content: center;
}

.drawer-content .completed {
  margin: 1.5rem 0 1.5rem 0.5rem;
}

@media only screen and (max-width: 1500px) {
  .overview-container .calendar {
    width: 120%;
  }
}

.fc-toolbar.fc-header-toolbar {
  background-color: white;
  padding: 1.5em 0 0 3rem;
}

.fc-view-container {
  background-color: #f7f7fc;
  border-color: transparent;
}

.fc-row.fc-widget-header {
  padding: 0.6rem 0;
  color: #a0a3bd;
  font-weight: normal;
}

.fc-day-number {
  font-weight: 700;
}

td.fc-today .fc-day-number {
  background-color: #00af9b;
  color: white;
  padding: 2px 8px;
  border-radius: 16px;
}

.fc-right .fc-today-button {
  display: none;
}

.fc .fc-createAppointment-button {
  background-color: #348D84 !important;
  color: white;
  box-shadow: none;
  font-weight: 600;
  font-family: 'Poppins';
  font-size: 14px;
  text-transform: capitalize;
  letter-spacing: 0.75px;
  border-radius: 4px !important;
}

.fc-unthemed th,
.fc-unthemed td,
.fc-unthemed thead,
.fc-unthemed tbody,
.fc-unthemed .fc-divider,
.fc-unthemed .fc-row,
.fc-unthemed .fc-content,
.fc-unthemed .fc-popover,
.fc-unthemed .fc-list-view,
.fc-unthemed .fc-list-heading td {
  border-color: transparent !important;
}

.fc-unthemed td.fc-today {
  background: transparent !important;
}

.fc .fc-row .fc-content-skeleton table,
.fc .fc-row .fc-content-skeleton td,
.fc .fc-row .fc-mirror-skeleton td {
  padding-right: 1rem;
}

.fc-button-primary {
  background-color: transparent !important;
  border-color: transparent !important;
}

.fc-icon-chevron-right,
.fc-icon-chevron-left {
  color: #4e4b66;
}

.fc-event,
.fc-event-dot {
  background-color: #c8e5ff !important;
}

.fc-event.fc-event-cancelled{
  background-color: #E4E4E4 !important;
  color: #8F8F8F !important;
  text-decoration: line-through !important;
}

.fc-event-cancelled .fc-title,.fc-event-cancelled .fc-time{
  color: #8F8F8F !important;
}

.fc-event {
  border: none !important;
  border-left: 4px solid #00af9b !important;
  cursor: pointer;
}

.fc-day-grid-event {
  margin: 5px 2px 0 !important;
  padding: 5px 3px !important;
}

.fc-title,
.fc-time {
  color: #016fd0;
  font-weight: 600;
}

.MuiAutocomplete-fullWidth {
  width: 100% !important;
}

.profile .header {
  padding-bottom: 1.25rem;
  margin-bottom: 2rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  justify-content: space-between;
  display: flex;
}

.profile .title {
  color: #4a4a49;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
}


.displayName {
  display: 'flex';
  flex-direction: 'row';
  align-items: 'center';
  justify-content: 'flex-start';
  font-weight: 500;
}
.MuiTablePagination-selectLabel  {
  margin-top: 12px;
}
.MuiTablePagination-displayedRows{
  margin-top: 15px;
}

