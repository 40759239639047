.app-preview-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 80%;
  max-width: 360px;
  margin: 0 auto;
}

.app-preview-container .grey-background {
  background: #6e71911c;
  height: 60vh;
  width: 100%;
  border-radius: 8px;
  overflow-y: hidden;
}

.app-preview {
  height: 100%;
  width: 90%;
  max-width: 325px;
  margin: 0 auto;
  border-radius: 1rem;
  background: #FFFFFF;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.app-preview h4 {
  text-align: center;
  font-family: Poppins;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  color: #6E7191;
}

.app-preview h6 {
  text-align: center;
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
}

.app-preview p {
  color: #6E7191;
}

.app-preview hr {
  width: 80%;
  margin: 0 auto 1rem;
}

.app-preview .mockh6 {
  height: 1rem;
  width: 40%;
  background: #6e71913b;
  border-radius: .25rem;
  margin-bottom: 1rem;
}

.app-preview .mockp {
  height: 1rem;
  width: 100%;
  background: #6e71911f;
  border-radius: .25rem;
}

.cover-photo-preview {
  background: #6e71913b;
  width: 100%;
  height: 180px;
  border-bottom-left-radius: 100px;
  border-bottom-right-radius: 100px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
  object-fit: cover;
}

/* app preview looks funky when screen is too small */
@media only screen and (max-width: 800px) {
  .app-preview-container {
    display: none;
  }
}
