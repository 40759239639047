.side-panel-image-container {
  margin: auto;
  margin-top: 30%;
  width: 100%;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.side-panel-image-container .image {
  width: 100%;
  max-width: 400px;
}

.side-panel-image-container .caption {
  margin-top: 24px;
  max-width: 400px;
  
  font-family: 'SF Pro Display Regular';
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  text-align: center;

  /* Cool Grey/Grey 400 */
  color: #A0A3BD;
}

@media only screen and (max-width: 600px) {
  .side-panel-image-container {
    display: none;
  }
}
