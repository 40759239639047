.fc-toolbar.fc-header-toolbar {
  background-color: white;
  padding: 1.5em 0 0 3rem;
}

.fc-view-container {
  background-color: #f7f7fc;
  border-color: transparent;
}

.fc-row.fc-widget-header {
  padding: 0.6rem 0;
  color: #a0a3bd;
  font-weight: normal;
}

.fc-day-number {
  font-weight: 700;
}

td.fc-today .fc-day-number {
  background-color: #00af9b;
  color: white;
  padding: 2px 8px;
  border-radius: 16px;
}

.fc-right .fc-today-button {
  display: none;
}

.fc .fc-createAppointment-button {
  background-color: #348D84 !important;
  color: white;
  box-shadow: none;
  font-weight: 600;
  font-family: 'Poppins';
  font-size: 14px;
  text-transform: capitalize;
  letter-spacing: 0.75px;
  border-radius: 4px !important;
}

.fc-unthemed th,
.fc-unthemed td,
.fc-unthemed thead,
.fc-unthemed tbody,
.fc-unthemed .fc-divider,
.fc-unthemed .fc-row,
.fc-unthemed .fc-content,
.fc-unthemed .fc-popover,
.fc-unthemed .fc-list-view,
.fc-unthemed .fc-list-heading td {
  border-color: transparent !important;
}

.fc-unthemed td.fc-today {
  background: transparent !important;
}

.fc .fc-row .fc-content-skeleton table,
.fc .fc-row .fc-content-skeleton td,
.fc .fc-row .fc-mirror-skeleton td {
  padding-right: 1rem;
}

.fc-button-primary {
  background-color: transparent !important;
  border-color: transparent !important;
}

.fc-icon-chevron-right,
.fc-icon-chevron-left {
  color: #4e4b66;
}

.fc-event,
.fc-event-dot {
  background-color: #c8e5ff !important;
}

.fc-event.fc-event-cancelled{
  background-color: #E4E4E4 !important;
  color: #8F8F8F !important;
  text-decoration: line-through !important;
}

.fc-event-cancelled .fc-title,.fc-event-cancelled .fc-time{
  color: #8F8F8F !important;
}

.fc-event {
  border: none !important;
  border-left: 4px solid #00af9b !important;
  cursor: pointer;
}

.fc-day-grid-event {
  margin: 5px 2px 0 !important;
  padding: 5px 3px !important;
}

.fc-title,
.fc-time {
  color: #016fd0;
  font-weight: 600;
}
