.displayName {
  display: 'flex';
  flex-direction: 'row';
  align-items: 'center';
  justify-content: 'flex-start';
  font-weight: 500;
}
.MuiTablePagination-selectLabel  {
  margin-top: 12px;
}
.MuiTablePagination-displayedRows{
  margin-top: 15px;
}