.stepper {
  position: absolute;
  top: 5rem;
  right: 4rem;
}

.stepper .svg-circle {
  position: relative;
  top: -3px;
  width: 32px;
  height: 32px;
  margin: -8px -8px -8px 12px;
}

.stepper .svg-circle rect {
  fill: none;
}

.stepper .circle {
  height: 1rem;
  width: 1rem;
  border-radius: 50%;
  margin-left: 20px;
  display: inline-block;
  background-color: white;
  color: #A0A3BD;
  box-shadow: 0 0 0 1px;
  position: relative;
}

.stepper .circle.active {
  color: #00AF9B
}

.stepper .circle.checked+label {
    position: absolute;
    top: 0.1rem;
    right: 0;
    font-size: 1rem;
    line-height: 0.8;
}

@media only screen and (max-width: 600px) {
  .stepper {
    position: relative;
    top: 2rem;
    margin: auto;
    left: 0;
    z-index: 1;
  }
}
