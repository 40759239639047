.onboarding-container {
  background-color: #FFFFFF;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  height: 100vh;
  width: 100vw;
  overflow-y: scroll;
  position: relative;
}

.onboarding-container h1 {
  color: #4A4A49;
  font-weight: bold;
  font-size: 24px;
  line-height: 35px;
}

.onboarding-container h1 > em {
  font-style: normal;
  color: #00C996;
}

.onboarding-container h2 {
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
}

.onboarding-container p {
  margin-bottom: 28px;
  font-family: 'SF Pro Display Regular';
}

.onboarding-container .content-container {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 65%;
  height: fit-content;
  padding-bottom: 10%;
}

.onboarding-container .content {
  margin: auto;
  margin-top: 200px;
  text-align: left;
  max-width: 620px;
  width: 100%;
  padding: 50px 50px;

  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.02em;
  color: #6E7191;
}

.onboarding-container .content.less-top-margin {
  margin-top: 150px;
}

.onboarding-container .actions {
  position: absolute;
  left: 0;
  bottom: 0;
  margin-top: 10%;
  margin-bottom: 10%;
  padding: 0 20%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.onboarding-container .actions .button-container {
  width: 100%;
  max-width: 184px;
}

.onboarding-container .actions .button-container.next {
  float: right;
}

@media only screen and (max-width: 600px) {
  .onboarding-container {
    flex-direction: column;
  }

  .onboarding-container .content-container {
    width: 100%;
  }

  .onboarding-container .content {
    padding: 1rem;
    margin-top: 2rem;
    width: 100%;
  }

  .onboarding-container .content.less-top-margin {
    margin-top: 2rem;
  }

  .onboarding-container .actions {
    position: relative;
    bottom: 0;
    margin-bottom: 10%;
    width: 100%;
  }

  .actions .button-container {
    max-width: 50%;
    width: 150px;
  }
}
