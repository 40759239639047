.image-uploader {
  width: 100%;
  height: 142px;

  background: rgba(247, 247, 252, 0.3);
  
  border: 1px dashed #6E7191;
  box-sizing: border-box;
  border-radius: 8px;
  
  /* Inside Auto Layout */
  display: flex;
  cursor: pointer;
  margin-bottom: 1rem;
  text-align: center;
}

.image-uploader.filled {
  border: none;
}

.image-uploader.logo {
  width: 142px;
}

.image-uploader img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.image-uploader span {
  margin-top: .5rem;
  font-size: 1rem;
  font-weight: 500;
}

.camera-icon path {
  stroke: #D9DBE9;
}

.camera-icon circle {
  stroke: #D9DBE9;
}
